.buttonsUpdatedColors.buttonsUpdatedColors {
  right: 192px;
  left: initial;
  top: 44px;
  width: 191px;
  flex-direction: initial;
  align-items: flex-end;
  padding: initial;
  outline: initial;
  outline-offset: initial;
  background-color: initial;
}
.learnMore {
  color: #002677;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  z-index: 0;
  width: 143px;
  flex-direction: column;
}
.footerAreaGuest.footerAreaGuest {
  bottom: -1px;
}
._2023OptumIncAllRightsReserved {
  color: #323334;
  font-size: 16px;
  line-height: 20px;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text2 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text3 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.thisIsPlaceholderTextThisIsWhe {
  color: #323334;
  font-size: 12px;
  line-height: 16px;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.icon {
  width: 100%;
  height: 100%;
}
.iconsMediumUser.iconsMediumUser {
  position: initial;
}
.person_filled.person_filled {
  position: initial;
  top: initial;
  left: initial;
}
.person_filled {
  position: initial;
  top: initial;
  left: initial;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.card.card {
  left: 300px;
  top: 400px;
  width: 410px;
  outline: solid 1px #e5e5e6;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px #19191a29;
}
.headingImage.headingImage {
  align-items: flex-start;
  text-align: justify;
  margin-left: 0;
}
.heading {
  color: #323334;
  font-size: 23.008px;
  line-height: 28px;
  font-weight: bold;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
  margin-left: 0;
}
.subheadingContent.subheadingContent {
  align-items: flex-start;
}
.bodyCopy {
  color: #4b4d4f;
  font-size: 16px;
  line-height: 20px;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.cTA.cTA {
  align-items: flex-start;
  text-align: justify;
    margin-left: 0;
}
.button.button.button {
  width: min-content;
  align-self: initial;
}
.buttonLabel {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.buttonLabel2 {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.root {
  position: relative;
  width: 1440px;
  height: 943px;
  align-items: flex-start;
  background-color: #fff;
}
.card2 {
  position: absolute;
  left: 730px;
  top: 400px;
  width: 410px;
  height: 240px;
  flex-direction: column;
  padding: 32px 0;
  outline: solid 1px #e5e5e6;
  outline-offset: -1px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 1px 4px 0px #19191a29;
  margin-left: 0;
}
.headingImage2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  margin-left: 0;

}
.heading2 {
  height: min-content;
  align-self: stretch;
  padding: 0 24px 16px;
}
.heading3 {
  color: #323334;
  font-size: 23.008px;
  line-height: 28px;
  font-weight: bold;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.mainContent {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 52px;
}
.subheadingContent2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
}
.bodyCopy2 {
  color: #4b4d4f;
  font-size: 16px;
  line-height: 20px;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.cTA2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 0 24px;
  margin-left: 0;
}
.header {
  position: absolute;
  left: 0;
  top: 96px;
  width: 1440px;
  height: 224px;
  align-items: flex-start;
  background-color: #d9f6fa;
}
.frame7083 {
  position: absolute;
  left: 392px;
  top: 64px;
  width: min-content;
  height: min-content;
  align-items: center;
  gap: 16px;
}
.accessOptumPayWith {
  color: #002677;
  font-size: 36px;
  line-height: 48px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.image1 {
  width: 221px;
  height: 39px;
  background-image:/* webpackIgnore: true */ url('/assets/image1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bodyCopy3 {
  color: #4b4d4f;
  font-size: 20px;
  line-height: 24px;
  font-family: Arial, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 493px;
  top: 136px;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
