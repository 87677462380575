.storybrainResets,
.storybrainResets * {
  box-sizing: border-box;
  padding: 0;
  background: none;
  border: none;
  flex-shrink: 0;
  text-decoration: none;
  min-width: 0;
  overflow-wrap: anywhere;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storybrainResets,
.storybrainResets :not(span, a, ul, ol, li, p) {
  display: flex;
}

.storybrainResets ul,
.storybrainResets ol {
  text-indent: 0;
  padding-inline-start: 1.5em;
}

.storybrainResets p:empty::before {
  content: '';
  display: inline-block;
}

.storybrainResets img {
  object-fit: contain;
}

.storybrainResets svg {
  overflow: visible;
}
