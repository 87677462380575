.root {
  position: relative;
  width: 24px;
  height: 24px;
  align-items: flex-start;
  overflow: hidden;
}
.chevronRight {
  position: absolute;
  left: 37.5%;
  right: 35%;
  top: 26.6667%;
  bottom: 26.6667%;
  overflow: visible;
}
.icon {
  width: 100%;
  height: 100%;
}
