.footer.footer {
  left: 84px;
  bottom: 0;
  top: initial;
  width: 1272px;
  height: 200px;
  padding: initial;
}
.content.content {
  padding: 32px 0 40px;
}
.text {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text2 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text3 {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.thisIsPlaceholderTextThisIsWhe {
  color: #323334;
  font-size: 12px;
  line-height: 16px;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.root {
  position: absolute;
  left: 0;
  bottom: 743px;
  width: 1440px;
  height: 200px;
  align-items: flex-start;
  box-shadow: 0px -8px 0px #929496, inset 0px 8px 0px #929496;
}
.bottomFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1440px;
  height: 200px;
  background-color: #fff;
  overflow: hidden;
}
