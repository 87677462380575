.root {
  position: relative;
  width: 24px;
  height: 24px;
  align-items: flex-start;
  overflow: hidden;
}
.openInNew {
  position: absolute;
  left: 12.5%;
  right: 12.5%;
  top: 12.5%;
  bottom: 12.5%;
  overflow: visible;
}
.icon {
  width: 100%;
  height: 100%;
}
