.rightIcon.rightIcon {
  width: 18px;
  height: 18px;
}
.icon {
  width: 100%;
  height: 100%;
}
.root {
  width: min-content;
  height: min-content;
  align-items: center;
  gap: 4px;
}
.text {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
