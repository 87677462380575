.root {
  position: absolute;
  left: 0;
  top: 0;
  width: 1440px;
  height: 96px;
  align-items: flex-start;
  background-color: #fbf9f4;
  box-shadow: inset 0px -1px 0px #e0e0e0;
}
.optumFinancialLogo {
  position: absolute;
  left: 84px;
  top: 32px;
  width: 250px;
  height: 32px;
}
.icon {
  width: 100%;
  height: 100%;
}
