.button.button {
  width: initial;
  align-self: stretch;
}
.button2.button2 {
  width: initial;
  align-self: stretch;
}
.root {
  position: absolute;
  left: 0;
  top: 0;
  width: 509px;
  height: min-content;
  flex-direction: column;
  padding: 32px 0;
  outline: solid 1px #7d7f81;
  outline-offset: -1px;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
}
.headingImage {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.heading {
  height: min-content;
  align-self: stretch;
  padding: 0 24px 16px;
}
.heading2 {
  color: #323334;
  font-size: 23.008px;
  line-height: 28px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.image {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 32px;
}
.image2 {
  height: 229px;
  align-self: stretch;
  background-image:/* webpackIgnore: true */ url('assets/image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mainContent {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 32px;
}
.subheadingContent {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
}
.heading3 {
  color: #4b4d4f;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.bodyCopy {
  color: #4b4d4f;
  font-size: 16px;
  line-height: 20px;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.cTA {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;
}
