.text {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text2 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text3 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text4 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text5 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text6 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.text7 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.rightIcon.rightIcon {
  width: 18px;
  height: 18px;
}
.icon {
  width: 100%;
  height: 100%;
}
.text8 {
  color: #0c55b8;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-decoration: underline;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.rightIcon2.rightIcon2 {
  width: 18px;
  height: 18px;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.root {
  position: absolute;
  left: 0;
  top: 0;
  width: 1440px;
  height: min-content;
  flex-direction: column;
  padding: 32px 0 0;
  background-color: #fff;
}
.topBorder {
  max-width: 100%;
  height: 0;
  max-height: 100%;
  align-self: stretch;
  outline: solid 2px #929496;
  background-color: #929496;
}
.content {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 40px;
  padding: 32px 32px 40px;
}
.copyrightLinks {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 40px;
}
.copyrightLine {
  width: min-content;
  height: min-content;
}
._2023OptumIncAllRightsReserved {
  color: #323334;
  font-size: 16px;
  line-height: 20px;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.links {
  height: min-content;
  flex: 1;
  align-items: center;
  gap: 20px;
  padding: 2px 0;
  overflow: hidden;
}
.legalText {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.thisIsPlaceholderTextThisIsWhe {
  color: #323334;
  font-size: 12px;
  line-height: 16px;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
