.root {
  width: min-content;
  height: min-content;
  place-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  border-radius: 28px;
  background-color: #002677;
  overflow: hidden;
}
.buttonLabel {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  font-family: Optum Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
